import React from 'react';
import Head from 'next/head';
import { NextWebVitalsMetric } from 'next/app';
import { SWRConfig } from 'swr';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NavigationProvider } from '~/context/use-navigation.context';
import { LayoutProvider } from '~/context/layout.context';
// import { disableClipboardData } from '~/helpers/disable-copy-on-page.helper'
import '~/styles/critical.scss';
import { useInitQueryClient } from '~/libs/queries/use-query-client';
import { AppPropsWithLayout } from '~/page-types/types';
import { SessionProvider } from 'next-auth/react';
import { NextDeliveryTimeProvider } from '~/services/basket/use-next-delivery-time';
import { GA4webVitals } from '~/libs/ga4';
import { InvoiceLayoutProvider } from '~/widgets/overview/invoices-widget/invoices.context';
import { apiClient } from '~/services/api-client';
import { captureException } from '@sentry/nextjs';
import { GTM_ID } from '~/libs/ga4/ga4';
import { useCulture } from '~/shared/scripts/cookie-consent';
import { config } from '../../env.config';

export function reportWebVitals(metric: NextWebVitalsMetric) {
    // Track performance based on the real experience
    GA4webVitals(metric);
}

export type CustomQueryProviderProps = {
    children?: React.ReactNode | React.ReactNode[];
};

export const CustomQueryProvider = ({ children }: CustomQueryProviderProps) => {
    const queryClient = useInitQueryClient();

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const initialDataLayer = {
    buildID: config.NEXT_PUBLIC_BUILD_ID,
    environment: config.NEXT_PUBLIC_ENVIRONMENT,
};

export type CookieConsentAndTrackingProps = {
    children?: React.ReactNode | React.ReactNode[];
};

export function CookieConsentAndTracking({ children }: CookieConsentAndTrackingProps) {
    const culture = useCulture();

    return (
        <>
            <Head>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js" defer></script>
                {process.env.NODE_ENV === 'production' ? (
                    <script
                        id="CookieConsent"
                        type="text/javascript"
                        data-culture={culture}
                        src="//policy.app.cookieinformation.com/uc.js"
                        data-gcm-version="2.0"
                        defer
                    />
                ) : null}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer=[${JSON.stringify(
                            initialDataLayer,
                        )}];(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://tracking.ftz.dk/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');;`,
                    }}
                    defer
                />
            </Head>
            {children}
        </>
    );
}

export default function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
    const getLayout = Component.getLayout && !pageProps.error ? Component.getLayout : (page: any) => page;

    return (
        <SessionProvider session={session} refetchInterval={0}>
            <NextDeliveryTimeProvider>
                <CustomQueryProvider>
                    <SWRConfig
                        value={{
                            fetcher: (url) => apiClient.auth(session).get(url),
                            onError: (error) => {
                                // Only log unknown errors
                                // TODO: what to do with errors?
                                if (!error?.name) {
                                    // eslint-disable-next-line no-console
                                    captureException(error);
                                    console.error('SWR', error);
                                }
                            },
                        }}
                    >
                        <Hydrate state={pageProps.dehydratedState}>
                            <LayoutProvider>
                                <CookieConsentAndTracking>
                                    <InvoiceLayoutProvider>
                                        <NavigationProvider>{getLayout(<Component {...pageProps} />)}</NavigationProvider>
                                    </InvoiceLayoutProvider>
                                </CookieConsentAndTracking>
                            </LayoutProvider>
                        </Hydrate>
                    </SWRConfig>

                    <ReactQueryDevtools position="top-left" initialIsOpen={false} toggleButtonProps={{ className: 'fetch-debugger' }} />
                </CustomQueryProvider>
            </NextDeliveryTimeProvider>
        </SessionProvider>
    );
}
