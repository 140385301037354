const parseBoolean = (variable: string): boolean => {
    return variable === 'true';
};

const getEnvironmentVariable = (environmentVariableObj: { key: string; value?: string }): string => {
    const environmentVariable = environmentVariableObj.value;
    if (!environmentVariable) {
        // When we are more certain of this, we can throw a hard error like this:
        // throw new Error(`Environment variable: ${environmentVariableObj.key} not found.`);
        console.error(`Environment variable ${environmentVariableObj.key} not found.`);
        return '';
    } else {
        return environmentVariable;
    }
};

// Needed for NextJS, as dynamic lookup like process.env['NEXT_PUBLIC_ENVIRONMENT'] wont be inlined.
// https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables
const envs = {
    NEXT_PUBLIC_ENVIRONMENT: {
        key: 'NEXT_PUBLIC_ENVIRONMENT',
        value: process.env.NEXT_PUBLIC_ENVIRONMENT,
    },
    NEXT_PUBLIC_BUILD_ID: {
        key: 'NEXT_PUBLIC_BUILD_ID',
        value: process.env.NEXT_PUBLIC_BUILD_ID,
    },
    NEXT_PUBLIC_SENTRY_ENABLED: {
        key: 'NEXT_PUBLIC_SENTRY_ENABLED',
        value: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
    },
    NEXT_PUBLIC_SENTRY_DSN: {
        key: 'NEXT_PUBLIC_SENTRY_DSN',
        value: process.env.NEXT_PUBLIC_SENTRY_DSN,
    },
};

export const config = {
    NEXT_PUBLIC_ENVIRONMENT: getEnvironmentVariable(envs.NEXT_PUBLIC_ENVIRONMENT),
    NEXT_PUBLIC_SENTRY_ENABLED: parseBoolean(getEnvironmentVariable(envs.NEXT_PUBLIC_SENTRY_ENABLED)),
    NEXT_PUBLIC_SENTRY_DSN: getEnvironmentVariable(envs.NEXT_PUBLIC_SENTRY_DSN),
    NEXT_PUBLIC_BUILD_ID: getEnvironmentVariable(envs.NEXT_PUBLIC_BUILD_ID),
};

export const SENTRY_ALLOWED_URL_REGEX = /https:\/\/(www\.)?(shop|test-shop)\.ftz\.dk/;
