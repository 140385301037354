import { NextWebVitalsMetric } from 'next/app';
import { convertGeneric, resetBasket } from '~/libs/ga4/converters/convert-generic';
import { ConverterItem, ConverterItemWithOpts } from '~/libs/ga4/converters/convert-generic/convert-generic';
import { GA4 } from '~/libs/ga4/types/ga4';
import { findBasketItemByItemId, getBasketGroup, getFlattendedGroupItems, lowerCamel, multiplyCurrency } from '~/libs/ga4/utils';
import { UpdateBasketItemProps } from '~/libs/queries/basket/hooks/use-update-basket';
import { Basket as BasketTypes } from '~/models/basket';
import { Products as ProductTypes } from '~/models/products';

declare global {
    interface Window {
        dataLayer: any;
    }
}

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
// TODO: Remove duplicate code found in gtm.ts when UA is deprecated
const pushToDataLayer = <T = Record<string, any>>(obj: T) => {
    try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(obj);
    } catch (error) {
        // console.log(error)
    }
};

const TRACKED_WEB_VITALS: NextWebVitalsMetric['name'][] = ['INP', 'CLS', 'FID', 'LCP'];
export const GA4webVitals = (metric: NextWebVitalsMetric) => {
    try {
        if (!TRACKED_WEB_VITALS.includes(metric.name)) {
            return;
        }

        pushToDataLayer({
            event: `web_vital_${metric.name.toLowerCase()}`,
            web_vital_value: metric.value,
        });
    } catch {
        console.error('GA4webVitals error');
    }
};

export const GA4AddToCart = (product: ConverterItem, opts: GA4.BasketEventOpts) => {
    try {
        const {
            basketId,
            price,
            quantity,
            currency,
            listName,
            content,
            productType,
            employeeID,
            promotionId,
            componentId,
            componentName,
            productId,
            campaignSpot,
            campaignType,
            campaignId,
            itemName,
        } = opts;

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.AddToCartEvent>({
            event: 'add_to_cart',
            cart_id: basketId,
            employeeID: employeeID,
            value: multiplyCurrency(price, quantity),
            content: content,
            ecommerce: {
                items: [
                    convertGeneric(product, {
                        currency,
                        quantity,
                        price,
                        listName,
                        productType,
                        promotionId,
                        componentId,
                        componentName,
                        productId,
                        campaignSpot,
                        campaignType,
                        campaignId,
                        itemName,
                    }),
                ],
            },
        });
    } catch {
        console.error('GA4AddToCart error');
    }
};

export type GA4CartUpdateProps = {
    previousBasket: BasketTypes.IBasketDto;
    updatedBasket: BasketTypes.IBasketDto;
    updatedItem: UpdateBasketItemProps;
    catalogType: ProductTypes.CatalogType;
    currency: string;
    listName: string;
    employeeId?: string;
};

export function GA4CartUpdate({ previousBasket, updatedBasket, updatedItem, catalogType, currency, listName, employeeId }: GA4CartUpdateProps) {
    const existingGroup = getBasketGroup(previousBasket.groups, updatedItem.carId);
    const existingItem = existingGroup?.items?.find(findBasketItemByItemId(updatedItem.itemId));

    if (existingItem?.count && existingItem?.count > updatedItem.count) {
        const flattenedUpdatedItems = getFlattendedGroupItems(previousBasket.groups);

        GA4removeFromCart(existingItem, {
            price: updatedItem.ftzPrice,
            itemName: updatedItem.itemName,
            productType: catalogType,
            quantity: existingItem.count - updatedItem.count,
            content: flattenedUpdatedItems?.map((itm) => itm.itemId)?.join(),
            employeeID: employeeId,
            basketId: updatedBasket.id,
            componentName: updatedItem.componentName,
            currency,
            listName,
            campaignSpot: updatedItem.campaignSpot,
            campaignType: updatedItem.componentName,
            campaignId: updatedItem.componentId,
        });

        return;
    }

    const addedGroup = getBasketGroup(updatedBasket.groups, updatedItem.carId);
    const addedItem = addedGroup?.items?.find(findBasketItemByItemId(updatedItem.itemId));

    if (addedItem && (!existingItem || existingItem.count < updatedItem.count)) {
        const flattenedUpdatedItems = getFlattendedGroupItems(updatedBasket.groups);
        GA4AddToCart(addedItem, {
            price: updatedItem.ftzPrice,
            productType: catalogType,
            quantity: updatedItem.count - (existingItem?.count || 0),
            content: flattenedUpdatedItems?.map((itm) => itm.itemId)?.join(),
            employeeID: employeeId,
            basketId: updatedBasket.id,
            currency,
            listName,
            promotionId: updatedItem.promotionId,
            componentId: updatedItem.componentId,
            componentName: updatedItem.componentName,
            campaignSpot: updatedItem.campaignSpot,
            campaignType: updatedItem.componentName,
            campaignId: updatedItem.componentId,
            itemName: updatedItem.itemName,
        });
    }
}

export const GA4AddListToCart = (products: ConverterItemWithOpts[], opts: GA4.ListBasketEventOpts) => {
    try {
        const { basketId, value, listName } = opts;

        const items = products.map((product) => {
            const { currency, price, quantity } = product.opts;
            return convertGeneric(product, {
                currency,
                quantity,
                price,
                listName,
            });
        });

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.AddToCartEvent>({
            event: 'add_to_cart',
            cart_id: basketId,
            value: value,
            ecommerce: {
                items,
            },
        });
    } catch {
        console.error('GA4AddListToCart error');
    }
};

export const GA4removeFromCart = (product: ConverterItem, opts: GA4.BasketEventOpts) => {
    try {
        const { basketId, price, quantity, currency, content, productType, campaignSpot } = opts;
        const productOpts = {
            currency,
            quantity,
            price,
            productType,
            campaignSpot,
        };

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.RemoveFromCartEvent>({
            event: 'remove_from_cart',
            cart_id: basketId,
            content: content,
            ecommerce: {
                items: [convertGeneric(product, productOpts)],
                value: multiplyCurrency(price, quantity),
            },
        });
    } catch {
        console.error('GA4removeFromCart error');
    }
};

export const GA4RemoveListFromCart = (products: ConverterItemWithOpts[], opts: GA4.RemoveListFromCartEventProps) => {
    try {
        const { currency, listName, value, basketId } = opts;

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.RemoveFromCartEvent>({
            event: 'remove_from_cart',
            cart_id: basketId,
            ecommerce: {
                items: products.map((p) => convertGeneric(p, { ...p.opts, listName })),
                value,
                currency,
            },
        });
    } catch {
        console.error('GA4RemoveListFromCart error');
    }
};

export const GA4SelectItem = (product: ConverterItem, opts: GA4.BasketEventOpts) => {
    try {
        const { price, quantity, currency, promotionId = '', componentId = '', componentName = '', listName = 'unknown', campaignSpot } = opts;

        const productOpts = {
            currency,
            quantity,
            price,
            listName,
            index: 0,
            promotionId,
            componentId,
            componentName,
            campaignSpot,
        };
        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.SelectItemEvent>({
            event: 'select_item',
            ecommerce: {
                items: [convertGeneric(product, productOpts)],
            },
        });
    } catch {
        console.error('GA4SelectItem error');
    }
};

export const GA4ViewItem = (product: ConverterItem, opts: GA4.ViewItemOpts) => {
    try {
        const { price, currency, productId, productType } = opts;

        const productOpts = {
            currency,
            price,
            index: 0,
            productId,
            productType,
        };

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.ViewItemEvent>({
            event: 'view_item',
            ecommerce: {
                items: [convertGeneric(product, productOpts)],
            },
        });
    } catch {
        console.error('GA4ViewItem error');
    }
};

export const GA4ViewListItems = (products: ConverterItem[], opts: GA4.ViewListEventOpts) => {
    try {
        const { currency, oeMatches = {}, listName } = opts;

        const items: GA4.TrackingProduct[] = products
            .filter((product) => product && 'price' in product && product.price)
            .map((product, index) => {
                const oeMatch = 'id' in product && oeMatches[product.id as string];

                return convertGeneric(product, {
                    currency,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    price: 'price' in product && 'customerPrice' in product?.price ? product.price.customerPrice : 0,
                    index,
                    oeMatch,
                    listName,
                });
            });
        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.ViewItemListEvent>({
            event: 'view_item_list',
            ecommerce: {
                items,
            },
        });
    } catch {
        console.error('GA4ViewListItems error');
    }
};

export const GA4ModifyWishlist = (product: ConverterItem, opts: GA4.ModifyWishlistOpts) => {
    try {
        const { price, currency, type, content } = opts;
        const productOpts = {
            currency,
            price,
            index: 0,
        };

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.AddToWishlistEvent>({
            event: type === 'add' ? 'add_to_wishlist' : 'remove_from_wishlist',
            content: content,
            ecommerce: {
                items: [convertGeneric(product, productOpts)],
            },
        });
    } catch {
        console.error('GA4AddToWishlist error');
    }
};

export const GA4BeginCheckout = (products: ConverterItemWithOpts[], opts: GA4.BeginCheckoutEventOpts) => {
    try {
        const { basketId, value, currency, listName } = opts;

        const items = products.map((product) => {
            const { currency, price, quantity, oeMatch, productType } = product.opts;
            return convertGeneric(product, {
                currency,
                quantity,
                price,
                listName,
                oeMatch,
                productType,
            });
        });

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.BeginCheckoutEvent>({
            event: 'begin_checkout',
            cart_id: basketId,
            ecommerce: {
                items,
                value,
                currency,
            },
        });
    } catch {
        console.error('GA4BeginCheckout error');
    }
};

export const GA4CompleteCheckout = (products: ConverterItemWithOpts[], opts: GA4.PurchaseEventProps) => {
    try {
        const {
            basketId,
            value,
            currency,
            listName,
            deliveryDate,
            deliveryMethod,
            deliveryType,
            vat,
            shippingCost,
            transactionId,
            employeeID,
            manualOrder,
        } = opts;

        const items = products.map((product) => {
            const { currency, price, quantity, oeMatch, stockStatus, productType } = product.opts;
            return convertGeneric(product, {
                currency,
                quantity,
                price,
                listName,
                oeMatch,
                stockStatus,
                productType,
            });
        });

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.BasketShippingEvent>({
            event: 'add_shipping_info',
            cart_id: basketId,
            ecommerce: {
                items,
                currency,
                value,
                shipping_tier: deliveryMethod,
            },
        });

        pushToDataLayer({ ecommerce: null });
        pushToDataLayer<GA4.PurchaseEvent>({
            event: 'purchase',
            cart_id: basketId,
            employeeID: employeeID,
            delivery_date: deliveryDate,
            delivery_type: deliveryType,
            delivery_method: deliveryMethod,
            manual_order: manualOrder,
            ecommerce: {
                items,
                value,
                currency,
                transaction_id: transactionId,
                tax: vat,
                shipping: shippingCost,
            },
        });
    } catch {
        console.error('GA4CompleteCheckout error');
    }
    resetBasket();
};

export const GA4ExtendPurchaseCall = async (data: GA4.PurchaseApiData) => {
    return fetch('https://tracking.ftz.dk/mp/collect?api_secret=u19C01_DSTi3gFN6IKfLXg&measurement_id=G-3T6SGFSKF0', {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => {
            console.log('failed to track purchase event', err);
            return null;
        });
};

/**
 * Custom events
 */
export const GA4SearchFullSearch = (term: string, total: number) => {
    try {
        pushToDataLayer<GA4.SearchEvent>({
            event: 'full_search',
            search_term: term,
            results_on_page: total,
        });
    } catch {
        console.error('GA4SearchFullSearch error');
    }
};

export const GA4SearchMiniSearch = (term: string) => {
    try {
        pushToDataLayer<GA4.SearchEvent>({
            event: 'mini_search',
            search_term: term,
        });
    } catch {
        console.error('GA4SearchMiniSearch error');
    }
};

export const GA4SearchMiniSearchClick = (term: string, selectedTerm: string) => {
    try {
        pushToDataLayer<GA4.MiniSearchClickEvent>({
            event: 'mini_search_click',
            search_term: term,
            search_term_selected: selectedTerm,
        });
    } catch {
        console.error('GA4SearchMiniSearchClick error');
    }
};

const GABasket = (action: GA4.BasketAction) => {
    try {
        pushToDataLayer<GA4.BasketEvent>({
            event: 'basket_functions',
            basket_action: action,
        });
    } catch {
        console.error('GABasket error');
    }
};

export const GA4BasketDelete = () => GABasket('Delete');
export const GA4BasketLoad = () => GABasket('Load');
export const GA4BasketSave = () => GABasket('Save');

export const GA4Download = (): void => pushToDataLayer({ event: 'download' });
export const GA4ExternalLink = (href: string) => {
    try {
        pushToDataLayer({
            event: 'outgoing_links',
            outgoing_link: href.replace('https://', ''),
        });
    } catch {
        console.error('GA4ExternalLink error');
    }
};
export const GA4EmailLInk = () => pushToDataLayer({ event: 'contact_email' });
export const GA4PhoneLink = () => pushToDataLayer({ event: 'contact_phone' });

export const GA4InternalLink = (href: string, campaignSpot: string) => {
    try {
        pushToDataLayer({
            event: 'internal_links',
            internal_link: href.replace('https://', ''),
            campaign_spot: campaignSpot,
        });
    } catch {
        console.error('GA4InternalLink error');
    }
};

export const GA4NewsletterSignup = () => pushToDataLayer({ event: 'newsletter_signup' });

export const GA4ProductInfoPlp = (info: GA4.ProductInfoPlpType) => {
    try {
        pushToDataLayer({
            event: 'product_info_plp',
            product_info: lowerCamel(info),
        });
    } catch {
        console.error('GA4ProductInfoPlp error');
    }
};

export const GA4ProductInfoTab = (info: string) => {
    try {
        pushToDataLayer({
            event: 'product_info',
            selected_product_info: info,
        });
    } catch {
        console.error('GA4ProductInfoTab error');
    }
};

export const GA4SeePreviousOrder = () => pushToDataLayer({ event: 'see_previous_order' });
export const GA4AddPreviousOrderToCart = () => pushToDataLayer({ event: 'added_previous_order_to_cart' });

export const GA4PageNotFound = (url: string, referer: string) => {
    try {
        pushToDataLayer({
            event: '404_page',
            page_url_404: url,
            page_referrer_404: referer,
        });
    } catch {
        console.error('GA4PageNotFound error');
    }
};

export const GA4PageView = (eventInfo: GA4.PageViewEvent) => {
    try {
        pushToDataLayer({
            event: 'pageview',
            ...eventInfo,
        });
    } catch {
        console.error('GA4PageView error');
    }
};

export const GA4ReportError = () => pushToDataLayer({ event: 'report_error' });
export const GA4CarInfoAdditional = (infoType: GA4.CarInfoAdditionalType) => {
    try {
        pushToDataLayer({
            event: 'carinfo_additional',
            info_type: lowerCamel(infoType),
        });
    } catch {
        console.error('GA4CarInfoAdditional error');
    }
};
export const GA4LatestCars = () => pushToDataLayer({ event: 'latest_cars' });
export const GA4CarInfoShowMore = () => pushToDataLayer({ event: 'carinfo_show_more' });

export const GA4FilterPlp = (filter: string) => {
    try {
        pushToDataLayer({ event: 'filters', filter_type: filter });
    } catch {
        console.error('GA4FilterPlp error');
    }
};

export const GA4Login = (event: GA4.LoginEvent) => {
    try {
        pushToDataLayer({ event: 'login', ...event });
    } catch {
        console.error('GA4Login error');
    }
};

export const GA4SisterCategoryClicked = (event: GA4.SisterCategoryClickEvent) => {
    try {
        pushToDataLayer({ event: 'sister_category_clicked', ...event });
    } catch {
        console.error('GA4SisterCategoryClicked error');
    }
};

export const GA4CreateCreditFromPdp = (items: string[]) => {
    try {
        pushToDataLayer({ event: 'create_credit_pdp', items });
    } catch {
        console.error('GA4CreateCreditFromPdp error');
    }
};
export const GA4CreateCreditQuickScan = (itemNo: string) => {
    try {
        pushToDataLayer({ event: 'create_credit_quick_scan', itemNo });
    } catch {
        console.error('GA4CreateCreditQuickScan error');
    }
};

export const GA4CreateCreditFinalize = (summary: GA4.CreateCreditSummary) => {
    try {
        pushToDataLayer({ event: 'create_credit_new', summary });
    } catch {
        console.error('GA4CreateCreditFinalize error');
    }
};

export const GA4CampaignClicked = (event: GA4.CampaignClickEvent) => {
    try {
        pushToDataLayer({ event: 'campaign_clicked', ...event });
    } catch {
        console.error('GA4CampaignClicked error');
    }
};

export const GA4CarInspectionReminder = (employeeID?: string, employeeCustomerID?: string) => {
    try {
        pushToDataLayer({
            event: 'car_inspection_reminder',
            employee_id: employeeID,
            employee_customer_id: employeeCustomerID,
        });
    } catch (error) {
        console.error('GA4CarInspectionReminder error');
    }
};

export const GA4CopyTruckOENumber = (reference: string, manufacturer: string) => {
    pushToDataLayer({
        event: 'truck_copy_oe',
        oe_reference: reference,
        manufacturer,
    });
};

export const GA4RequestOEProduct = (reference: string, manufacturer: string) => {
    pushToDataLayer({
        event: 'truck_request_oe',
        oe_reference: reference,
        manufacturer,
    });
};
